@keyframes fadeIn{
    from{
        opacity: 0.2;
    }
    to{
        opacity: 1;
    }
}

.contact-container{
    padding: 200px;
    text-align: center;
    animation: fadeIn 1s ease-in forwards;
}

.h1{
    color: #327655;
}

a:link {
    color: #327655;
    text-decoration: none;
}

a:visited {
    color: #327655;
    text-decoration: none;
}