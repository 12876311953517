@import url('../assets/fonts/fonts.css');

@keyframes fadeIn{
    from{
        opacity: 0.2;
    }
    to{
        opacity: 1;
    }
}

.container{
    display: flex;
    flex-direction: row;
    padding: 200px;
    text-align: center;
    color:#585858;
}

.container h1{
    font-family: 'Muli';
    font-weight: 100;
    font-size: 54px;
    color: #327655;
}

.column{
    flex: 1;
    padding: 20px;
    animation: fadeIn 1s ease-in forwards;
}

.container img{
    width: 500px;
    opacity:100;
    animation: fadeIn 1s ease-in forwards;
}

.container text{
    animation: fadeIn 1s ease-in forwards;
}

