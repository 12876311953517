.services-container{
    padding: 200px 200px;
    text-align: center;
    color:#585858;
    list-style: none;
    animation: fadeIn 1s ease-in forwards;
}

.column{
    flex: 1;
    padding: 20px;
}

h1{
    font-family: 'Muli';
    font-weight: 100;
    font-size: 54px;
    color: #327655;
}

.services-list{
    list-style: none;
    text-align: left;
}
