.navi {
    width: 100%;
    position: fixed;
    overflow: hidden; /* No horizontal scrolling */
    background-color: #fff;
    padding: 20px 20px;
    border-bottom: 1px solid #d4d4d4;
    z-index: 1000;
}

.navi-items{
    display: flex;
    color: #000;
    float: right;
    align-items: center;
    text-align: center;
    text-decoration: none;
    font-size: 18px;
    list-style: none;
    margin-bottom:0px;
}

.item {
    height: 70px;
    margin-right:30px;
    padding: 20px;
}

.item a{
    color: #222222;
    text-decoration: none;
}

.item:hover{
    border-bottom: 4px solid #222222;
    transition: all 0.2s ease-in-out;  
}

/* Logo */
.logo img{
    margin-left: 15px;
    height: 70px;
}