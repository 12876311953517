.schedule-container{
    display: flex;
    flex-direction: row;
    padding: 200px 200px;
    text-align: center;
    color:#585858;
    list-style: none;
}

.schedule-list{
    list-style: none;
    text-align: center;
}

.schedule-list li{
    margin-bottom: 10px;
}

.column{
    flex: 1;
    padding: 20px;
}

h1{
    font-family: 'Muli';
    font-weight: 100;
    font-size: 54px;
    color: #327655;
}